import React, { useEffect, useState } from 'react'
import { 
  Row,
  Col,
  Form, Input,
  FormGroup, Label,
  Card, CardBody, CardHeader
} from 'reactstrap'
import _ from 'lodash'
import InternalEvaluation from './InternalEvaluation'
import ExternalEvaluation from './ExternalEvaluation'
import ExternalJSON from '../assets/external.json'
import InternalJSON from '../assets/internal.json'

import { FieldData } from '../assets/index'

const FormContent = ({
  selectedData
}) => {

  const [ selectedAcceptedVacancies, onSelectAcceptedVacancies ] = useState( null )

  useEffect(() => {
    if ( selectedData ){
      onSelectAcceptedVacancies( selectedData.internship )
    }
  }, [ selectedData ])
  
  return (
    <>
      <Form onSubmit={ e => e.preventDefault()}>
        <Card>
          <CardHeader style={{ fontSize: '1rem' }}>
            Internship
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg={ 12 }>
                <FormGroup>
                  {
                    selectedAcceptedVacancies && (
                      <>
                        <Label>Assigned Internship</Label>
                        <Row className={ 'mt-2' }>
                          <Col lg={ 6 }>
                            <div className={ 'd-flex align-items-center' }>
                              <i  id={ 'TooltipStudent' } className="font-size-lg header-icon pe-7s-study mr-2 text-muted opacity-6"/>
                              { `Student name - ` }
                              <b className={ 'text-warning ml-1' }>{ ` ${ selectedAcceptedVacancies.student_name }` }</b>
                            </div>
                            <div className={ 'd-flex align-items-center mt-2' }>
                              <i className="font-size-lg header-icon pe-7s-culture mr-2 text-muted opacity-6"/>
                              { `Company name -` }
                              <b className={ 'text-warning ml-1' }>{ `${ selectedAcceptedVacancies.company_name }` }</b>
                            </div>
                          </Col>
                          <Col lg={ 6 }>
                            <div className={ 'd-flex align-items-center mt-2' }>
                              <i className="font-size-lg header-icon pe-7s-users mr-2 text-muted opacity-6"/>
                              { `Internal Supervisor -` }
                              <b className={ 'text-warning ml-1' }>{ `${ selectedAcceptedVacancies.internal_supervisor }` }</b>
                            </div>
                            <div className={ 'd-flex align-items-center mt-2' }>
                              <i className="font-size-lg header-icon pe-7s-users mr-2 text-muted opacity-6"/>
                              { `External Supervisor -` }
                              <b className={ 'text-warning ml-1' }>{ `${ selectedAcceptedVacancies.external_supervisor }` }</b>
                            </div>
                          </Col>  
                        </Row> 
                      </>
                    )
                  }
                  {
                    !selectedAcceptedVacancies && (
                      <>
                        <Label>Assigned Internship</Label>
                        <br />
                        <i className={ 'text-warning' } style={{ fontSize: '0.85rem' }}>
                          No internship is assigned yet.
                        </i>
                      </>
                    )
                  }
                </FormGroup>
              </Col>
              {
                FieldData.map( field => (
                  <Col lg={ field.col || 12 }>
                    <FormGroup check={ field.type === 'checkbox' }>
                      <Label check={ field.type === 'checkbox' }>
                        {
                          field.type === 'checkbox' && (
                            <Input
                              type={ field.type }
                              rows={ 5 }
                              disabled={ true }
                              checked={ selectedData[ field.value ] }
                            />
                          )
                        }
                        { `${ field.label } ` }
                        { field.required && <span className="text-danger">*</span> }
                      </Label>
                      {
                        field.type === 'textarea' && (
                          <Input
                            type={ field.type }
                            rows={ 5 }
                            disabled={ true }
                            value={ selectedData[ field.value ] }
                          />
                        )
                      }
                    </FormGroup>
                  </Col>
                ))
              }
            </Row>
          </CardBody>
        </Card>
        {
          selectedData.type && (
            <Card className={ 'mt-3' }>
              <CardHeader style={{ fontSize: '1rem' }}>INTERN’S EVALUATION </CardHeader>
              <CardBody>
                <i>Please state the grade based on the rubrics in the appendix.</i>
                {
                  selectedData.type === 'Internal' && (
                    <InternalEvaluation tempJSON={ JSON.parse( selectedData.evaluation) }/>
                  )
                }
                {
                  selectedData.type === 'External' && (
                    <ExternalEvaluation tempJSON={ JSON.parse( selectedData.evaluation) }/>
                  )
                }
              </CardBody>
            </Card>
          )
        }
      </Form>
    </>
  )
}

export default FormContent