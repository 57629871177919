import React, { useEffect, useState } from 'react'
import { 
  Row,
  Col,
  Form, Input,
  FormGroup, Label,
  Card, CardBody, CardHeader
} from 'reactstrap'
import InternalEvaluation from '../../EvaluationForm/components/InternalEvaluation'
import ExternalEvaluation from '../../EvaluationForm/components/ExternalEvaluation'
import ExternalJSON from '../../EvaluationForm/assets/external.json'
import InternalJSON from '../../EvaluationForm/assets/internal.json'

const FieldData = [
  { label: `Supervisor's General comments`, value: 'general_comments', type: 'textarea' },
  { label: 'I am recommend the student a Pass. ', value: 'is_student_passed', type: 'checkbox' },
  { label: 'I am recommend the student for the Industrial Training Certificate of Excellence. ', value: 'is_recommend_industrial_training_excellence', type: 'checkbox' },
  { label: 'The student is awarded as best intern. ', value: 'is_best_intern_awards', type: 'checkbox' },
  { label: 'Submission is confirmed. ', value: 'is_confirmed_submission', type: 'checkbox' },
  { label: '', value: 'Final total_marks' },
]
const FinalReportEvaluation = props => {

  const { selectedAcceptedVacancies, is_update } = props
  const [ tempJSON, onChangeJSON ] = useState( ExternalJSON )
  const [ formData, setFormData ] = useState({
    type: null,
    general_comments: '',
    is_student_passed: false,
    is_recommend_industrial_training_excellence: false,
    is_best_intern_awards: false,
    is_confirmed_submission: false,
  })

  useEffect( () => {
    setFormData({ ...selectedAcceptedVacancies.internship_evaluation_form })
    if (selectedAcceptedVacancies.internship_evaluation_form) {
      onChangeJSON( JSON.parse(selectedAcceptedVacancies.internship_evaluation_form.evaluation) )
    }
  }, [ selectedAcceptedVacancies ])

  return(
    <>
      <Form onSubmit={ e => e.preventDefault()}>
          <Card>
            <CardHeader style={{ fontSize: '1rem' }}>
              Internship
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg={ 12 }>
                  <FormGroup>
                    {
                      selectedAcceptedVacancies && (
                        <>
                          <Label>Assigned Internship</Label>
                          <Row className={ 'mt-2' }>
                            <Col lg={ 6 }>
                              <div className={ 'd-flex align-items-center' }>
                                <i  id={ 'TooltipStudent' } className="font-size-lg header-icon pe-7s-study mr-2 text-muted opacity-6"/>
                                { `Student name - ` }
                                <b className={ 'text-warning ml-1' }>{ ` ${ selectedAcceptedVacancies.student_name }` }</b>
                              </div>
                              <div className={ 'd-flex align-items-center mt-2' }>
                                <i className="font-size-lg header-icon pe-7s-culture mr-2 text-muted opacity-6"/>
                                { `Company name -` }
                                <b className={ 'text-warning ml-1' }>{ `${ selectedAcceptedVacancies.company_name }` }</b>
                              </div>
                            </Col>
                            <Col lg={ 6 }>
                              <div className={ 'd-flex align-items-center mt-2' }>
                                <i className="font-size-lg header-icon pe-7s-users mr-2 text-muted opacity-6"/>
                                { `Internal Supervisor -` }
                                <b className={ 'text-warning ml-1' }>{ `${ selectedAcceptedVacancies.internal_supervisor }` }</b>
                              </div>
                              <div className={ 'd-flex align-items-center mt-2' }>
                                <i className="font-size-lg header-icon pe-7s-users mr-2 text-muted opacity-6"/>
                                { `External Supervisor -` }
                                <b className={ 'text-warning ml-1' }>{ `${ selectedAcceptedVacancies.external_supervisor }` }</b>
                              </div>
                            </Col>  
                          </Row> 
                        </>
                      )
                    }
                    {
                      !selectedAcceptedVacancies && (
                        <>
                          <Label>Assigned Internship</Label>
                          <br />
                          <i className={ 'text-warning' } style={{ fontSize: '0.85rem' }}>
                            No internship is assigned yet.
                          </i>
                        </>
                      )
                    }
                  </FormGroup>
                </Col>
                {
                  formData.type &&  FieldData.map( field => (
                    <Col lg={ field.col || 12 }>
                      <FormGroup check={ field.type === 'checkbox' }>
                        <Label check={ field.type === 'checkbox' }>
                          {
                            field.type === 'checkbox' && (
                              <Input
                                type={ field.type }
                                rows={ 5 }
                                disabled={ true }
                                checked={ formData[ field.value ] }
                              />
                            )
                          }
                          { `${ field.label } ` }
                          { field.required && <span className="text-danger">*</span> }
                        </Label>
                        {
                          field.type === 'textarea' && (
                            <Input
                              disabled={ true }
                              type={ field.type }
                              rows={ 5 }
                              value={ formData[ field.value ] || '' }
                            />
                          )
                        }
                      </FormGroup>
                    </Col>
                  ))
                }
              </Row>
            </CardBody>
          </Card>
          {
            formData.type && (
              <Card className={ 'mt-3' }>
                <CardHeader style={{ fontSize: '1rem' }}>INTERN’S EVALUATION </CardHeader>
                <CardBody>
                  <i>Please state the grade based on the rubrics in the appendix</i>
                  {
                    formData.type === 'Internal' && (
                      <InternalEvaluation 
                        tempJSON={ tempJSON }
                        disabled={ !is_update }
                        onChangeJSON={ onChangeJSON }
                      />
                    )
                  }
                  {
                    formData.type === 'External' && (
                      <ExternalEvaluation
                        tempJSON={ tempJSON }
                        disabled={ !is_update }
                        onChangeJSON={ onChangeJSON }
                      />
                    )
                  }
                </CardBody>
              </Card>
            )
          }
        </Form>
        {
          !formData.type && (
            <Card className={ 'mt-3' }>
              <CardHeader style={{ fontSize: '1rem' }}>INTERN’S EVALUATION </CardHeader>
              <CardBody>
                <p className='student-vacancy__detail-subtitle'>No Evaluation Form Found.</p>
              </CardBody>
            </Card>
          )
        }
    </>
  )
}

export default FinalReportEvaluation;